import React from "react";
import { motion } from "framer-motion";
import logo from './storklogo.png'

const StripeOnboardingComplete = () => {
  const openApp = () => {
    window.location.href = "stork://gift.storkcares.com/stripe-onboarding-complete";
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        background: "linear-gradient(to bottom right, #EBF8FF, #D6BCFA)",
        textAlign: "center",
        padding: "16px",
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        style={{
          background: "white",
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
          borderRadius: "24px",
          padding: "32px",
          maxWidth: "400px",
          width: "100%",
        }}
      >
        <motion.img
          src={logo}
          alt="Stork and Stripe Logo"
          style={{
            width: "128px",
            margin: "0 auto 24px auto",
            display: "block",
          }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.2, type: "spring", stiffness: 260, damping: 20 }}
        />
        <motion.h1
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            color: "#1A202C",
            marginBottom: "16px",
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          Welcome to the Stork Family! 🎉
        </motion.h1>
        <motion.p
          style={{
            color: "#718096",
            marginBottom: "24px",
            lineHeight: "1.5",
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
        >
          Your Stripe onboarding is complete! You’re now ready to enjoy secure
          payments, transparent contributions, and family-focused features.
        </motion.p>
        <motion.ul
          style={{
            textAlign: "left",
            color: "#718096",
            marginBottom: "24px",
            listStyleType: "disc",
            paddingLeft: "20px",
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          <li>Safe and seamless payments powered by Stripe.</li>
          <li>Track contributions in your personalized Stork Wallet.</li>
          <li>Access campaigns tailored to your family’s needs.</li>
        </motion.ul>
        <motion.button
          onClick={openApp}
          style={{
            padding: "12px 32px",
            background: "linear-gradient(to right, #4299E1, #9F7AEA)",
            color: "white",
            fontWeight: "bold",
            borderRadius: "999px",
            border: "none",
            cursor: "pointer",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            marginBottom: "24px",
          }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Open Stork App
        </motion.button>
        <motion.p
          style={{
            fontSize: "14px",
            color: "#A0AEC0",
            marginTop: "16px",
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6 }}
        >
          New to Stork?{" "}
          <a
            href="https://storkcares.com"
            style={{
              color: "#4299E1",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            Learn more about our mission
          </a>.
        </motion.p>
      </motion.div>
    </div>
  );
};

export default StripeOnboardingComplete;
